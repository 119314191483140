import TagItem from '@/components/TagItem.vue'
import { type ArgTypes, useProjectListConfigQuery, useProjectListQuery } from '@/api/vq/projects'
import { type DataTableSortState, type PaginationProps } from 'naive-ui'
import { EFilterType, type TFilters } from '@/components/filters'
import { computed, h, reactive } from 'vue'

import { renderIconButton } from '@/utils/render-components'
import { useDebounceFn } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import type { ConfigOwnerFilterResponse } from '@/api'

export const decodeDateRange = (dateStr: string) => {
  // replace from:, to: and \
  const [from, to] = dateStr.split(';').map((dateStr: string) => dateStr.replace(/from:|to:|\\/g, ''))
  const fromDate = new Date(from)
  const toDate = new Date(to)

  if (isNaN(fromDate.getTime()) || isNaN(toDate.getTime())) {
    throw Error('Date object is not valid in decodeDateRange')
  }

  return [fromDate.getTime(), toDate.getTime()]
}

export const encodeDateRange = (dateRange: Array<number>) => {
  const [start, end] = dateRange
  const startDate = new Date(start)
  const endDate = new Date(end)

  if (isNaN(startDate.getTime()) || isNaN(endDate.getTime())) {
    throw Error('Date object is not valid in encodeDateRange')
  }

  const parsedStart = startDate.toISOString().replace(/:/g, '\\:')
  const parsedEnd = endDate.toISOString().replace(/:/g, '\\:')
  const dateStr = `from:${parsedStart};to:${parsedEnd}`

  return dateStr
}

export const useProjectsDataTable = (URLParams: ArgTypes<'projectList'> | undefined) => {
  const DEFAULT_LIMIT = 9

  const queryOptions = reactive<ArgTypes<'projectList'>>({
    page: Number(URLParams?.page) || 1,
    limit: Number(URLParams?.limit) || DEFAULT_LIMIT,
    archived: URLParams?.archived,
    tab: URLParams?.tab,
    search: URLParams?.search,
    orderBy: URLParams?.orderBy,
    labels: URLParams?.labels,
    owner: URLParams?.owner,
    created: URLParams?.created,
    lastModified: URLParams?.lastModified,
    language: URLParams?.language,
    dataSourceProvider: URLParams?.dataSourceProvider,
    include: undefined,
    inheritableColumnRef: undefined,
    inheritableProjectId: undefined,
  })

  const { data, isFetching, isLoading, isError, error, refetch, isPlaceholderData } = useProjectListQuery(queryOptions)

  const i18n = useI18n()
  const { data: configData } = useProjectListConfigQuery()

  const tableFiltersConfig = computed<TFilters>(() => [
    {
      type: EFilterType.Checkbox,
      key: 'labels',
      label: i18n.t('projects.tags'),
      items: configData.value?.filters.labels.allowed_values,
      value: queryOptions.labels ? queryOptions.labels.split(';') : undefined,
      renderCheckboxContent(filter) {
        return h(TagItem, { tag: filter })
      },
    },
    {
      type: EFilterType.Checkbox,
      key: 'owner',
      label: i18n.t('projects.owner'),
      items: configData.value?.filters.owner.allowed_values,
      value: queryOptions.owner ? queryOptions.owner.split(',') : undefined,
      // since this filter items are object, we need to define which key will be used for property mapping
      itemValueKey: 'email',
      searchItemKey: 'email',
      renderCheckboxContent: (filter: ConfigOwnerFilterResponse) =>
        h('div', null, [
          h('div', { class: 'font-500' }, filter.full_name),
          h('div', { class: 'text-xs font-500 c-gray-800' }, filter.email),
        ]),
    },
    {
      type: EFilterType.Date,
      key: 'created',
      label: i18n.t('projects.date_created'),
      value: queryOptions.created ? decodeDateRange(queryOptions.created) : undefined,
    },
    {
      type: EFilterType.Date,
      key: 'lastModified',
      label: i18n.t('projects.date_modified'),
      value: queryOptions.lastModified ? decodeDateRange(queryOptions.lastModified) : undefined,
    },
    {
      type: EFilterType.Checkbox,
      label: i18n.t('projects.language'),
      key: 'language',
      items: configData.value?.filters.language.allowed_values,
      value: queryOptions.language ? queryOptions.language.split(',') : undefined,
      renderCheckboxContent: (val: string) => h('div', { class: 'font-500' }, i18n.t(`common.language.${val}`)),
      renderTagContent: (val) => (val.length === 1 ? i18n.t(`common.language.${val[0]}`) : `(${val.length})`),
    },
    {
      type: EFilterType.Checkbox,
      key: 'dataSourceProvider',
      label: i18n.t('projects.source'),
      items: configData.value?.filters.data_source_provider.allowed_values,
      value: queryOptions.dataSourceProvider ? queryOptions.dataSourceProvider.split(',') : undefined,
      renderCheckboxContent: (val: string) => h('div', { class: 'font-500' }, i18n.t(`common.integrations.${val}`)),
      renderTagContent: (val) => (val.length === 1 ? i18n.t(`common.integrations.${val[0]}`) : `(${val.length})`),
    },
  ])

  const pagination = computed<PaginationProps>(() => ({
    page: queryOptions.page || 1,
    pageSize: queryOptions.limit || DEFAULT_LIMIT,
    itemCount: data.value?.count || 0,
    simple: true,
    next: () => renderIconButton('fa-chevron-right'),
    prev: () => renderIconButton('fa-chevron-left'),
    // advanced pagination settings
    // showSizePicker: true,
    // pageSizes: [5, 10, 15, 25, 50],
    // pageSlot: 7,
    // displayOrder: ['size-picker', 'pages', 'quick-jumper'],
    onChange: async (page: number) => {
      queryOptions.page = page
    },
    onUpdatePageSize: async (pageSize: number) => {
      queryOptions.limit = pageSize
      queryOptions.page = 1
    },
  }))

  // This function gets the updates from ProjectListFilters and sets the value to queryOptions accordingly.
  // Since query expects specific data, adjusting query data value happens here
  const handleFiltersUpdate = async (attr: keyof typeof queryOptions, val: Array<string | number> | string) => {
    const joiner = ['owner', 'dataSourceProvider', 'language'].includes(attr) ? ',' : ';'
    // NOTE: must be undefined if the array is empty
    let value = val?.length ? (Array.isArray(val) ? val.join(joiner) : val) : undefined

    // dates are decoded/encoded specifically, override value for here
    if (value && ['created', 'lastModified'].includes(attr)) {
      value = encodeDateRange(val as Array<number>)
    }

    // reset pagination to 1 if labels are updated
    if (attr === 'labels') {
      queryOptions.page = 1
    }

    // since we don't know if it is a string or undefined, we use any here
    queryOptions[attr] = value as never
  }

  const handleSorterChange = async (sort: DataTableSortState) => {
    let orderBy = 'desc:last_modified'

    if (typeof sort.order === 'string') {
      const order = sort.order.includes('desc') ? 'desc' : 'asc'

      orderBy = `${order}:${sort.columnKey}`
    }

    queryOptions.orderBy = orderBy
  }

  const handleShowArchivedChange = async (val: boolean) => {
    queryOptions.archived = val ? 'True' : 'False'
  }

  const handleSearchUpdate = useDebounceFn(async (val: string) => {
    queryOptions.search = val ? val : undefined
    queryOptions.page = 1
  }, 500)

  return {
    tableFiltersConfig,
    data,
    isFetching,
    isLoading,
    isError,
    error,
    isPlaceholderData,
    pagination,
    queryOptions,
    refetch,
    handleFiltersUpdate,
    handleSorterChange,
    handleSearchUpdate,
    handleShowArchivedChange,
  }
}
