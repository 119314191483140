<template>
  <div>
    <div class="flex items-center justify-between">
      <div>
        <NH2 class="mb-0">
          {{ $t('team.team_members') }}
        </NH2>
        <div class="text-subheader">
          {{ $t('team.description') }}
        </div>
      </div>

      <SearchInput v-model:value="searchKey" @update:value="handleSearchUpdate" />
    </div>

    <!-- Filters -->
    <div class="mr-2 mt-5 flex">
      <Container flex class="!rounded-20px">
        <DropdownFilter :filters-config="tableFiltersConfig" @update:filters="handleFiltersUpdate" />
        <SelectedFilters
          :filters-config="tableFiltersConfig"
          @update:filters="handleFiltersUpdate"
          @click:reset="resetFiltersAndSearch"
        />
      </Container>
    </div>

    <NDivider />

    <Container>
      <div class="mb-1 flex items-center justify-between px-2 py-1">
        <div class="font-500">{{ $t('common.matching_results') }}: {{ teamMembersListRes?.count }}</div>
        <NButton
          v-if="hasEditPermission"
          class="ml-auto"
          type="primary"
          icon
          :disabled="!newMemberAvailable"
          size="small"
          @click="$emit('update:showAddMemberModal', true)"
        >
          <template #icon>
            <FaIcon icon="far fa-user-plus" size="xs" />
          </template>
          {{ $t('team.invite') }}
        </NButton>
      </div>

      <NDataTable
        remote
        :loading="isLoading"
        :columns="headers"
        :data="teamMembersListRes?.results"
        :pagination="pagination"
        scroll-x="800"
      >
        <template #empty>
          <DataEmpty :message="$t('team.table.no_data')" @click:reset="resetFiltersAndSearch()" />
        </template>
      </NDataTable>
    </Container>
  </div>
</template>

<script setup lang="ts">
import DataEmpty from '@/components/DataEmpty.vue'
import { AccountService, type TeamMemberResponse } from '@/api'
import {
  type ArgTypes,
  useTeamMemberDeleteMutation,
  useTeamMemberListConfigQuery,
  useTeamMemberListQuery,
  useTeamMemberUpdateMutation,
} from '@/api/vq/organization'
import { NButton, NIcon, NTime, useMessage } from 'naive-ui'
import { computed, h, reactive, ref } from 'vue'
import { formatDate, formatTime } from '@/plugins/i18n'
import { getRoleTranslation, renderFaIcon, renderIconButton, renderTableTH } from '@/utils'
import { useAuthStore } from '@/store'
import { useDebounceFn } from '@vueuse/core'
import { useI18n } from 'vue-i18n'
import type { DataTableColumn, DataTableColumns, PaginationProps } from 'naive-ui'

import ConfirmTeamMemberDelete from './TeamMembersDeleteModal.vue'
import OrganizationRolesDropdown from './OrganizationRolesDropdown.vue'
import SearchInput from '@/components/SearchInput.vue'
import { DropdownFilter, EFilterType, SelectedFilters, type TFilters } from '@/components/filters'

defineProps<{
  hasEditPermission: boolean
  newMemberAvailable: boolean
}>()

defineEmits<{
  'update:showAddMemberModal': [boolean]
}>()

const i18n = useI18n()
const auth = useAuthStore()
const message = useMessage()
const queryOptions = reactive<ArgTypes<'teamMemberList'>>({
  page: 1,
  limit: 10,
  roles: undefined,
  activated: undefined,
  email: undefined,
  orderBy: 'desc:date_joined',
})
const searchKey = ref('')
const resendInvitationID = ref()

const { data: teamMembersListRes, isFetching: isFetchingMembers, refetch } = useTeamMemberListQuery(queryOptions)
const { data: teamMembersListConfig } = useTeamMemberListConfigQuery({})
const { mutateAsync: deleteUser, isPending: isDeletingUser } = useTeamMemberDeleteMutation()
const { mutateAsync: updateUser, isPending: isUpdatingUser } = useTeamMemberUpdateMutation()

const isLoading = computed(() => isFetchingMembers.value || isUpdatingUser.value)

const tableFiltersConfig = computed<TFilters>(() => [
  {
    type: EFilterType.Checkbox,
    key: 'roles',
    label: i18n.t('team.table.roles_label'),
    items: teamMembersListConfig.value?.filters.roles.allowed_values.map((v) => ({
      value: v,
      label: getRoleTranslation(v).name,
    })),
    itemValueKey: 'value',
    searchItemKey: 'label',
    value: queryOptions.roles ? queryOptions.roles.split(',') : undefined,
    sortSelected: false,
    renderCheckboxContent: (filter: { label: string }) => filter.label,
    renderTagContent: (val) => (val.length === 1 ? getRoleTranslation(String(val[0])).name : `(${val.length})`),
  },
  {
    type: EFilterType.Checkbox,
    key: 'activated',
    label: i18n.t('team.table.verified_label'),
    // TO DO: translate these
    items: ['yes', 'no'],
    value: queryOptions.activated === 'True' ? ['yes'] : queryOptions.activated === 'False' ? ['no'] : undefined,
    multiple: false,
    searchable: false,
    sortSelected: false,
    renderCheckboxContent: (filter: string) => i18n.t(`common.${filter}`),
    renderTagContent: (val) => i18n.t(`common.${val}`),
  },
])

const headers: DataTableColumns<TeamMemberResponse> = [
  {
    title: () => renderTableTH(i18n.t('team.table.user_label')),
    key: 'user',
    render: (row) =>
      h('div', null, [
        h('div', { class: 'font-500' }, row.name),
        h('div', { class: 'text-xs c-gray-800 line-height-4' }, row.email),
      ]),
  },
  {
    title: () => renderTableTH(i18n.t('team.table.joined_label')),
    key: 'date_joined',
    render: (row) =>
      h('div', null, [
        h('div', { class: 'font-500' }, formatDate(row.date_joined) || '-'),
        h('div', { class: 'text-xs c-gray-800 line-height-4' }, formatTime(row.date_joined) || '-'),
      ]),
  },
  {
    title: () => renderTableTH(i18n.t('team.table.login_label')),
    key: 'last_login',
    render: (row) => {
      if (!row.last_login) return '-'

      return h(NTime, { class: 'font-500 c-gray-800', time: new Date(row.last_login).getTime(), type: 'relative' })
    },
  },
  {
    title: () => renderTableTH(i18n.t('team.table.verified_label')),
    key: 'activated',
    render: (row) => {
      const icon = row.activated ? 'fa-check' : 'fa-close'
      const iconComponent = h(NIcon, { class: 'c-gray-800' }, () => renderFaIcon(icon, { size: '1x' }))
      const activatedText = h('span', { class: 'ms-2 font-500 c-gray-800' }, row.activated ? 'Active' : 'Inactive')

      // TO DO: translation
      const resendButton =
        auth.hasPermission('team_mgmt') &&
        !row.activated &&
        h(
          NButton,
          {
            loading: !!resendInvitationID.value,
            disabled: !!resendInvitationID.value,
            text: true,
            block: true,
            size: 'tiny',
            class: 'h-12px !w-auto',
            onClick: async () => {
              resendInvitationID.value = row.id
              await AccountService.teamMemberResendConfirmation({ id: row.id })
              // TO DO: translate
              message.success(i18n.t('team.table.invitation_resent'))
              resendInvitationID.value = undefined
            },
          },
          () => 'Resend invite'
        )

      return [iconComponent, activatedText, resendButton]
    },
  },
  {
    title: () => renderTableTH(i18n.t('team.table.role_label')),
    key: 'roles',
    render: (row) => {
      // check if has permission or user itself
      if (!auth.hasPermission('team_mgmt') || row.id === auth.user?.id) {
        return h('span', { class: 'font-500 c-gray-800' }, getRoleTranslation(row.role?.name).name)
      }

      return h(OrganizationRolesDropdown, {
        value: row.role?.id,
        'onUpdate:value': async (roleID) =>
          await updateUser({
            id: row.id,
            requestBody: {
              role: roleID,
            },
          }),
      })
    },
  },
  // show this column only if the user has permission to manage team
  ...(!auth.hasPermission('team_mgmt')
    ? []
    : [
        {
          key: 'actions',
          width: 80,
          render: (row) => {
            // dont show delete button if org owner or self
            if (auth.user?.id === row.id || auth.user?.organization?.root_user_id === row.id) return undefined

            return h(ConfirmTeamMemberDelete, {
              user: row,
              loading: isDeletingUser.value,
              'onClick:delete': async (
                user: TeamMemberResponse,
                action: 'transfer' | 'delete',
                closeDialog: () => void
              ) => {
                await deleteUser(
                  {
                    id: user.id,
                    transferAssets: action === 'transfer',
                  },
                  {
                    onSuccess: () => closeDialog(),
                  }
                )
              },
            })
          },
        } as DataTableColumn<TeamMemberResponse>,
      ]),
]

const pagination = computed<PaginationProps>(() => ({
  page: queryOptions.page || 1,
  pageSize: queryOptions.limit,
  pageSlot: 7,
  simple: true,
  next: () => renderIconButton('fa-chevron-right'),
  prev: () => renderIconButton('fa-chevron-left'),
  itemCount: teamMembersListRes.value?.count || 0,
  displayOrder: ['size-picker', 'pages', 'quick-jumper'],
  onChange: async (page: number) => {
    queryOptions.page = page
    await refetch()
  },
  onUpdatePageSize: async (pageSize: number) => {
    queryOptions.limit = pageSize
    queryOptions.page = 1
    await refetch()
  },
}))

const handleSearchUpdate = useDebounceFn(async (val: string) => {
  let value = val === '' ? undefined : val

  if (value) {
    value = `contains.i:${val}`
  }

  queryOptions.email = value
  queryOptions.page = 1
  await refetch()
}, 500)

// This function gets the updates from ProjectListFilters and sets the value to queryOptions accordingly.
// Since query expects specific data, adjusting query data value happens here
const handleFiltersUpdate = async (attr: keyof typeof queryOptions, val: Array<string> | string) => {
  let value = undefined

  if (!val || (val && !val.length)) {
    queryOptions[attr] = value
    await refetch()
    return
  }

  if (attr === 'email') value = (val as string[]).map((v) => `contains.i:${v}`).join(',')
  else if (attr === 'activated') {
    value = val === 'yes' ? 'True' : val === 'no' ? 'False' : undefined
  } else value = (val as string[]).join(',')

  queryOptions[attr] = value as never

  await refetch()
}

const resetFiltersAndSearch = async () => {
  tableFiltersConfig.value.forEach((filter) => {
    const key = filter.key as keyof typeof queryOptions

    queryOptions[key] = undefined
  })
  searchKey.value = ''
  queryOptions.page = 1
  queryOptions.email = undefined

  await refetch()
}
</script>

<style lang="scss" scoped></style>
