<template>
  <NSpin :show="loading" :stroke-width="0">
    <div class="min-h-36px mt-4">
      <Transition name="fade" mode="out-in">
        <div v-if="!selectedProjects || selectedProjects.length === 0" class="mr-2 flex">
          <slot />
        </div>

        <!-- checked row actions -->
        <div v-else class="flex items-center">
          <NSpace align="center">
            <span>
              {{ $t('projects.datatable.selected_rows', { n: selectedProjects.length, total: itemCount }) }}
            </span>
            <NButton size="small" @click="$emit('click:clear-selection')">{{ $t('common.clear_selection') }}</NButton>
          </NSpace>

          <!-- TO DO: to add functionality to the buttons -->
          <NSpace class="ml-auto">
            <ProjectsTagsDropdown :projects="selectedProjects" :available-tags="availableTags">
              <template #activator>
                <NButton size="small" @click="$emit('click:tag')">
                  <template #icon>
                    <FaIcon icon="fa-tag" size="2xs" />
                  </template>
                  {{ $t('common.tag') }}
                </NButton>
              </template>
            </ProjectsTagsDropdown>

            <NButton size="small" :type="selectedProjectsArchived ? 'success' : 'error'" @click="confirmArchive">
              <span v-if="selectedProjectsArchived">
                {{ $t('common.unarchive') }}
              </span>
              <span v-else>
                {{ $t('common.archive') }}
              </span>
              <template #icon>
                <FaIcon icon="fa-box-archive" size="2xs" />
              </template>
            </NButton>
          </NSpace>
        </div>
      </Transition>
    </div>
  </NSpin>
</template>

<script setup lang="ts">
import ProjectsTagsDropdown from './ProjectsTagsDropdown.vue'
import { computed, ref } from 'vue'
import { useProjectUpdateMutation } from '@/api/vq/projects'
import type { ProjectListUIConfigTabResponse, ProjectListUIResponse } from '@/api'

interface IProjectsTableActionsProps {
  loading?: boolean
  itemCount?: number
  selectedProjects: ProjectListUIResponse[]
  availableTags?: ProjectListUIConfigTabResponse[]
}

const props = withDefaults(defineProps<IProjectsTableActionsProps>(), {
  loading: false,
  itemCount: 0,
  selectedProjects: () => [],
})

const emit = defineEmits<{
  'click:clear-selection': []
  'click:new-project': []
  'click:tag': []
}>()

const { mutateAsync } = useProjectUpdateMutation()
const isLoading = ref(false)

const selectedProjectsArchived = computed(() => {
  return props.selectedProjects.every((r) => r.completed)
})

const confirmArchive = () => {
  // TO DO: translate
  const title = selectedProjectsArchived.value ? 'Unarchiving' : 'Archiving'
  const dialogType = selectedProjectsArchived.value ? 'success' : 'error'

  window.$dialog[dialogType]({
    title: `${title} ${props.selectedProjects.length} projects`,
    content: `Are you sure you want to ${selectedProjectsArchived.value ? 'unarchive' : 'archive'} selected projects?`,
    positiveText: selectedProjectsArchived.value ? 'Unarchive' : 'Archive',
    loading: isLoading as unknown as boolean,
    onPositiveClick: async () => {
      isLoading.value = true
      return await Promise.all(
        props.selectedProjects.map((r) => {
          return mutateAsync({
            id: r.id,
            requestBody: {
              archived: !r.completed,
            },
          })
        })
      ).finally(() => {
        isLoading.value = false

        emit('click:clear-selection')
      })
    },
  })
}
</script>

<style lang="scss" scoped></style>
