<!-- TODO: Translate -->
<template>
  <div>
    <NFormItem :path="formPath" v-bind="formItemProps">
      <NInput
        ref="inputRef"
        :type="type"
        :value="modelValue"
        :placeholder="placeholder"
        :input-props="inputProps"
        @input="(v: string) => $emit('update:modelValue', v)"
        @keydown.enter="$emit('on:next')"
        @blur="$emit('blur')"
      />
    </NFormItem>
    <slot />

    <!-- Forgot password -->
    <NSpace v-if="!step || step === LoginResponse.next_step.PASSWORD" justify="end">
      <NButton text :disabled="loading" type="primary" @click="$emit('on:reset')">
        {{ reset ? 'Back to login' : 'Forgot password' }}
      </NButton>
    </NSpace>
    <br />

    <div class="action-container">
      <!-- TO DO: enable later -->
      <!-- <NButton v-if="!step" type="primary" quaternary :disabled="loading">Sign Up</NButton> -->
      <NButton class="action-container__next" type="primary" :loading="loading" @click="$emit('on:next')">
        {{ reset ? 'Reset password' : 'Next' }}
      </NButton>
    </div>
  </div>
</template>

<script setup lang="ts">
import { LoginResponse } from '@/api'
import { onMounted, ref } from 'vue'
import type { FormItemProps, InputInst, InputProps } from 'naive-ui'

interface ILoginStepProps {
  modelValue?: string
  type?: 'text' | 'password' | 'textarea' | undefined
  placeholder?: string
  formPath?: string
  inputProps?: InputProps['inputProps']
  step?: string
  loading?: boolean
  reset?: boolean
  formItemProps?: FormItemProps
}

withDefaults(defineProps<ILoginStepProps>(), {
  modelValue: '',
  type: 'text',
  placeholder: '',
  step: '',
  reset: false,
})

defineEmits<{
  'update:modelValue': [value: string]
  'on:next': []
  'on:reset': []
  blur: []
}>()

const inputRef = ref<InputInst>()

onMounted(() => {
  inputRef.value?.focus()
})
</script>

<style lang="scss" scoped>
.action-container {
  display: flex;

  &__next {
    margin-left: auto;
  }
}
</style>
