import * as lightTheme from '@/plugins/naive-ui/light.theme'
import { type GlobalThemeOverrides, useThemeVars } from 'naive-ui'
import { breakpoints } from '@/plugins/naive-ui/shared'
import { colord } from 'colord'
import { computed, ref } from 'vue'
import { defineStore } from 'pinia'
import { kebabCase } from 'lodash-es'
import type { ThemeResponse } from '@/api'

export const supportedThemes: Array<{ label: string; value: TCaplenaTheme }> = [
  {
    label: 'Light',
    value: 'light',
  },
  {
    label: 'Dark',
    value: 'dark',
  },
]

const responsive = ref({
  xs: document.documentElement.clientWidth < breakpoints.s,
  s: document.documentElement.clientWidth >= breakpoints.s && document.documentElement.clientWidth < breakpoints.m,
  m: document.documentElement.clientWidth >= breakpoints.m && document.documentElement.clientWidth < breakpoints.l,
  l: document.documentElement.clientWidth >= breakpoints.l && document.documentElement.clientWidth < breakpoints.xl,
  xl: document.documentElement.clientWidth >= breakpoints.xl && document.documentElement.clientWidth < breakpoints.xxl,
  xxl: document.documentElement.clientWidth >= breakpoints.xxl,
})

const isListeningToResize = ref(false)
const watchResize = () => {
  responsive.value = {
    xs: document.documentElement.clientWidth < breakpoints.s,
    s: document.documentElement.clientWidth >= breakpoints.s && document.documentElement.clientWidth < breakpoints.m,
    m: document.documentElement.clientWidth >= breakpoints.m && document.documentElement.clientWidth < breakpoints.l,
    l: document.documentElement.clientWidth >= breakpoints.l && document.documentElement.clientWidth < breakpoints.xl,
    xl:
      document.documentElement.clientWidth >= breakpoints.xl && document.documentElement.clientWidth < breakpoints.xxl,
    xxl: document.documentElement.clientWidth >= breakpoints.xxl,
  }
}

const styleTagID = 'caplena-css-variables'

function createStyleElement() {
  const styleTag = document.createElement('style')

  styleTag.id = styleTagID

  return styleTag
}

function declareGlobalCSSVars(themeCommonOverrides: GlobalThemeOverrides['common'], customVars: TCustomThemeVars) {
  const variables = { ...themeCommonOverrides, ...customVars }
  const styleList: string[] = []
  const styleTag = document.getElementById(styleTagID) || createStyleElement()

  const pushVarsToList = (src: Record<string, string | number | object>, prefix = '') => {
    for (const key in src) {
      const value = src[key]

      if (typeof value === 'object') {
        pushVarsToList(value as Record<string, string | number>, key)
      } else {
        const cssKey = prefix ? `${prefix}-${key}` : key

        styleList.push(`--${kebabCase(cssKey)}: ${value}`)
      }
    }
  }

  pushVarsToList(variables)

  styleTag.innerHTML = `:root { ${styleList.join(';\n')}; }`
  document.head.insertAdjacentElement('afterbegin', styleTag)
}

function injectThemeNameToBody(oldName: TCaplenaTheme, newName: TCaplenaTheme) {
  document.body.classList.remove(oldName)

  if (newName === 'light') return
  document.body.classList.add(newName)
}

export const useThemeStore = defineStore('theme', () => {
  const current = ref<TCaplenaTheme>('light')
  const overrides = ref<GlobalThemeOverrides>()
  const customVars = ref<TCustomThemeVars>(lightTheme.customVars)
  const themeVars = computed(() => useThemeVars().value)

  const isMobile = computed(() => responsive.value.xs || responsive.value.s)

  // start listening to screen resize changes
  if (!isListeningToResize.value) window.addEventListener('resize', watchResize)

  async function loadTheme(name: TCaplenaTheme) {
    const module: IThemeModule = await import(`@/plugins/naive-ui/${name}.theme.ts`)

    return module
  }

  // light theme is included in the bundle by default
  // Any other theme is lodaded asynchronously
  async function setThemeWithName(name: TCaplenaTheme) {
    if (!supportedThemes.some((t) => t.value === name)) {
      console.error(`[ERROR]: Theme does not exist: ${name}`)
      return
    }

    const theme = name === 'light' ? lightTheme : await loadTheme(name)

    injectThemeNameToBody(current.value, name)
    current.value = name
    localStorage.setItem('theme', name)

    overrides.value = theme.themeOverrides
    customVars.value = theme.customVars

    // must be called once
    declareGlobalCSSVars(theme.themeOverrides.common, theme.customVars)
  }

  function setThemeValuesFromAPI(res: ThemeResponse) {
    if (!overrides.value?.common) return

    if (res.logo_url) customVars.value.logoUrl = res.logo_url

    if (res.primary_color) {
      overrides.value.common.primaryColor = res.primary_color
      overrides.value.common.primaryColorHover = colord(res.primary_color).darken(0.05).toHex()
      overrides.value.common.primaryColorPressed = res.primary_color
      overrides.value.common.primaryColorSuppl = res.primary_color
      overrides.value.common.primaryColor = res.primary_color
      customVars.value.chart.primary = res.primary_color
    }

    declareGlobalCSSVars(overrides.value.common, customVars.value)
  }

  return { current, overrides, customVars, themeVars, isMobile, responsive, setThemeWithName, setThemeValuesFromAPI }
})
