import ph, { PostHog, type Survey } from 'posthog-js'
import { type App, nextTick, ref } from 'vue'
import type { Router } from 'vue-router'

const DEBUG_FEATURE_FLAG = 'caplena_next_debug'
let posthog: void | PostHog

export const usePosthog = (app: App, router: Router) => {
  if (!import.meta.env.CAPP_POSTHOG_PROJECT_API_KEY || import.meta.env.DEV) return

  posthog = ph.init(import.meta.env.CAPP_POSTHOG_PROJECT_API_KEY, {
    api_host: import.meta.env.CAPP_POSTHOG_INSTANCE_ADDRESS,
    capture_pageview: true,
    disable_session_recording: import.meta.env.MODE === 'development' || import.meta.env.MODE === 'localapi',
    session_recording: {
      maskAllInputs: false,
      maskInputOptions: {
        password: true,
      },
    },
  })

  app.config.globalProperties.$posthog = posthog

  router.afterEach((to, from, failure) => {
    if (!failure) {
      nextTick(() => {
        // create a pageview event for each route change
        if (app.config.globalProperties.$posthog) {
          app.config.globalProperties.$posthog.capture('$pageview', { path: to.fullPath })
        }
      })
    }
  })
}

// This is a reactive variable that will be updated when the user is identified and feature flags are read, on Dev mode it will always be true
export const canUserSeeDebugFeatures = ref(import.meta.env.DEV)

// Do not use posthog instance directly, create functions that will use it
export const identifyPosthogUser = (id: string) => {
  return new Promise<void>((resolve) => {
    if (!posthog) return resolve()

    posthog.identify(id)

    posthog.reloadFeatureFlags()
    return posthog.onFeatureFlags(() => {
      // feature flags are guaranteed to be available at this point
      if (!posthog) {
        canUserSeeDebugFeatures.value = import.meta.env.DEV

        return resolve()
      }

      canUserSeeDebugFeatures.value = Boolean(posthog.isFeatureEnabled(DEBUG_FEATURE_FLAG))
      return resolve()
    })
  })
}

export const resetPosthogUser = () => {
  if (!posthog) return

  posthog.reset()
  posthog.reloadFeatureFlags()
}

export const getActiveSurveys = (callback: (surveys: Survey[]) => void) => {
  if (!posthog) return []

  return posthog.getActiveMatchingSurveys(callback)
}

export function itemsIfUserCanSeeDebugFeatures<T>(items: T[]): T[] {
  if (canUserSeeDebugFeatures.value) {
    return items
  }

  return []
}

export function isFeatureEnabled(featureFlag: string) {
  return posthog?.isFeatureEnabled(featureFlag)
}
