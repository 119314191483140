import { Userpilot } from 'userpilot'
import { i18n } from '@/plugins/i18n'
import type { Router } from 'vue-router'
import type { UserDetailResponse } from '@/api'

export const useUserpilot = (router: Router) => {
  if (!import.meta.env.CAPP_USERPILOT_TOKEN || import.meta.env.DEV) return

  Userpilot.initialize(import.meta.env.CAPP_USERPILOT_TOKEN)

  router.afterEach((to, from, failure) => {
    if (!failure) {
      // Reload Userpilot on Page Change
      Userpilot.reload()
    }
  })
}

export const identifyUserPilot = (user: UserDetailResponse | null, prefix: string) => {
  if (!user) Userpilot.anonymous()
  else {
    Userpilot.identify(`${prefix}_${user.id}`, {
      // Whatever else is needed to identify the user can be added here
      id: user.id,
      organization_id: user.organization?.id,
      language: i18n.global.locale.value,
    })
  }
}
