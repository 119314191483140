import BillingView from '@/views/billing'
import LoginView from '@/views/login'
import NotAuthorizedView from '@/views/_generic/not-authorized.view.vue'
import NotFoundView from '@/views/_generic/not-found.view.vue'
import ProjectsView from '@/views/projects'
import SettingsView from '@/views/settings'
import TeamView from '@/views/team'
import { type RouteRecordRaw, createRouter, createWebHistory } from 'vue-router'
import { h } from 'vue'
import { queryClient } from '@/plugins/vue-query'
import { renderErrorDialogIcon } from '@/utils'
import { useAppStore, useAuthStore } from '@/store'

const routes: RouteRecordRaw[] = [
  {
    path: '/',
    name: 'home',
    redirect: '/projects',
  },
  {
    path: '/projects',
    children: [
      {
        path: '',
        name: 'projects',
        component: ProjectsView,
      },
      {
        // route: projects/{projectId}
        path: ':projectId',
        name: 'projectDetail',
        component: () => import('@/views/projects/project-detail.view.vue'),
        props: (route) => ({ projectId: route.params.projectId }),
        children: [
          {
            path: 'overview',
            name: 'projectDetail_overview',
            component: () => import('@/views/projects/overview.view.vue'),
            props: (route) => ({ projectId: route.params.projectId }),
          },
          {
            path: 'data',
            name: 'projectDetail_data',
            component: () => import('@/views/projects/data.view.vue'),
            props: (route) => ({ projectId: route.params.projectId }),
          },
          {
            path: 'topics/:questionRef?',
            name: 'projectDetail_topics',
            component: () => import('@/views/projects/topics.view.vue'),
            props: (route) => ({ projectId: route.params.projectId, questionRef: route.params.questionRef }),
          },
          {
            path: 'reports',
            name: 'projectDetail_reports',
            component: () => import('@/views/reports/reports.view.vue'),
            props: (route) => ({ projectId: route.params.projectId }),
          },
          {
            path: 'alerts',
            name: 'projectDetail_alerts',
            component: () => import('@/views/projects/alerts.view.vue'),
            props: (route) => ({ projectId: route.params.projectId }),
          },
          {
            // route: projects/{projectId}/reports/{reportId}
            path: 'reports/:reportId',
            name: 'projectDetail_reportDetail',
            component: () => import('@/views/reports/report-detail.view.vue'),
            props: (route) => ({ projectId: route.params.projectId, reportId: route.params.reportId }),
            meta: {
              hideProjectSidebar: true,
            },
          },
          {
            path: 'settings',
            name: 'projectDetail_settings',
            props: (route) => ({ projectId: route.params.projectId }),
            component: () => import('@/views/projects/settings.view.vue'),
            redirect: { name: 'projectDetail_settings_general' },
            children: [
              {
                path: '',
                name: 'projectDetail_settings_general',
                component: () => import('@/views/projects/settings/general.view.vue'),
                props: (route) => ({ projectId: route.params.projectId }),
              },
              {
                path: 'permissions',
                name: 'projectDetail_settings_permissions',
                component: () => import('@/views/projects/settings/permissions.view.vue'),
                props: (route) => ({ projectId: route.params.projectId }),
              },
              {
                path: 'anonymization',
                name: 'projectDetail_settings_anonymization',
                component: () => import('@/views/projects/settings/anonymization.view.vue'),
                props: (route) => ({ projectId: route.params.projectId }),
              },
              {
                path: 'data',
                name: 'projectDetail_settings_data',
                component: () => import('@/views/projects/settings/data.view.vue'),
                props: (route) => ({ projectId: route.params.projectId }),
              },
            ],
          },
          {
            path: 'insight-chat',
            name: 'projectDetail_insightChat',
            component: () => import('@/views/projects/insight-chat.view.vue'),
            props: (route) => ({ projectId: route.params.projectId }),
          },
          {
            path: '',
            name: 'projectDetail_redirect',
            redirect: (to) => ({ params: to.params, name: 'projectDetail_overview' }),
          },
        ],
      },
    ],
  },
  {
    path: '/team',
    name: 'team',
    component: TeamView,
  },
  {
    path: '/billing',
    name: 'billing',
    component: BillingView,
  },
  {
    path: '/settings',
    name: 'settings',
    component: SettingsView,
    children: [
      {
        path: '',
        name: 'settings_account',
        component: () => import('@/views/settings/account.view.vue'),
      },
      {
        path: 'organization',
        name: 'settings_organization',
        component: () => import('@/views/settings/organization.view.vue'),
      },
      {
        path: 'theme',
        name: 'settings_theme',
        component: () => import('@/views/settings/theme.view.vue'),
      },
      {
        path: 'api',
        name: 'settings_api',
        component: () => import('@/views/settings/api.view.vue'),
      },
      {
        path: 'integrations',
        name: 'settings_integrations',
        component: () => import('@/views/settings/integrations.view.vue'),
      },
      {
        path: 'security',
        name: 'settings_security',
        component: () => import('@/views/settings/security.view.vue'),
      },
    ],
  },
  {
    path: '/debug',
    name: 'debug',
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('@/views/debug'),
    meta: {
      allowAnonymous: true,
    },
  },
  {
    path: '/login',
    name: 'login',
    component: LoginView,
    meta: {
      allowAnonymous: true,
      layout: undefined,
    },
  },
  {
    path: '/logout',
    name: 'logout',
    component: {
      async beforeRouteEnter(to, from, next) {
        const auth = useAuthStore()

        queryClient.cancelQueries()
        await auth.logout()
        next('/login')
      },
    },
  },
  {
    path: '/activate',
    name: 'activate',
    component: () => import('@/views/_generic/activation.view.vue'),
    meta: {
      allowAnonymous: true,
      layout: undefined,
    },
  },
  {
    path: '/maintenance',
    name: 'maintenance',
    component: () => import('@/views/_generic/maintenance.view.vue'),
    beforeEnter: (to, from, next) => {
      if (!useAppStore().maintenance.active) next('/')
      else next()
    },
    meta: {
      allowAnonymous: true,
      layout: undefined,
    },
  },
  {
    path: '/reporting/report-views/:reportViewId',
    name: 'sharedReportView',
    component: () => import('@/views/reports/shared-report-view.vue'),
    props: (route) => ({ reportViewId: route.params.reportViewId }),
    meta: {
      allowAnonymous: true,
      layout: 'SharedLayout',
    },
    children: [
      {
        path: ':reportId',
        name: 'sharedReportView_reportDetail',
        component: () => import('@/views/reports/report-detail.view.vue'),
        props: (route) => ({
          projectId: route.params.projectId,
          reportId: route.params.reportId,
          viewOnly: true,
        }),
      },
    ],
  },
  { path: '/not-authorized', name: '404', component: NotAuthorizedView },
  { path: '/:pathMatch(.*)*', name: '403', component: NotFoundView },
]

// add default route meta
routes.forEach((route) => {
  if (!route.meta) route.meta = {}
  if (!('layout' in route.meta)) route.meta.layout = 'DefaultLayout'
  if (!route.meta.allowAnonymous) route.meta.allowAnonymous = false
})

// create router
const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes,
})

// router navigation guards
router.beforeEach(async (to, from, next) => {
  const app = useAppStore()
  const auth = useAuthStore()
  const isPublic = to.meta?.allowAnonymous

  // we are closing all dialogs before navigating to a new route
  window.$dialog.destroyAll()

  if (!navigator.onLine) {
    // TO DO: translate
    window.$dialog.error({
      icon: () => renderErrorDialogIcon('far fa-wifi-exclamation'),
      title: 'Connection Error',
      content: () =>
        h('div', {
          innerHTML:
            // eslint-disable-next-line max-len
            'Your computer seems to have issues <b>connecting to the internet</b>. <br>Please make sure you have a working network connection and then reload the page.',
        }),
      positiveText: 'Close',
    })
  }

  if (!app.isInitialized) {
    await app.initialize(isPublic)
  }

  // TO DO: fix this when activation route is available
  // activationkey exist in query, redirect to activate route
  if (to.query.activationkey) {
    next('/activate')
    return
  }

  const authGuard = !isPublic && !auth.isLoggedIn
  const maintenanceGuard = !isPublic && app.maintenance.active && to.name !== 'maintenance'

  // check authentication
  if (authGuard) {
    const login = { name: 'login', query: {} }

    if (to.path !== '/') {
      login.query = { go_to: to.path }
    }

    next(login)
    // check if maintenance is active
  } else if (maintenanceGuard) {
    next({ name: 'maintenance' })
  } else {
    next()
  }
})

export default router
