import { computed, customRef, watch, watchEffect } from 'vue'
import { useAuthStore } from '@/store'
import { useProjectPermissionListQuery } from '@/api/vq/projects'

type TPermissionOption =
  | 'projects_view'
  | 'projects_download'
  | 'projects_edit'
  | 'projects_permissions'
  | 'projects_inherit'
  | 'projects_append'
  | 'projects_delete'

const permissionOptions: TPermissionOption[] = [
  'projects_view',
  'projects_download',
  'projects_edit',
  'projects_permissions',
  'projects_inherit',
  'projects_append',
  'projects_delete',
]

type TUserPermissions = Record<TPermissionOption, boolean | undefined>

const defaultUserPermissions: TUserPermissions = {
  projects_view: false,
  projects_download: false,
  projects_edit: false,
  projects_permissions: false,
  projects_inherit: false,
  projects_append: false,
  projects_delete: false,
}

export const useUserProjectPermissions = (projectId: string) => {
  const authStore = useAuthStore()

  const { data: userPermissionResponse } = useProjectPermissionListQuery(projectId, {
    users: authStore.user?.id,
  })

  const userPermissions = computed<TUserPermissions>(() => {
    if (!userPermissionResponse.value) return defaultUserPermissions

    const userResponse = userPermissionResponse.value.results[0]
    const permissions = permissionOptions.reduce<TUserPermissions>(
      (acc, permission) => {
        const hasPermission =
          userResponse.roles?.some((role) => role.permissions?.some((p) => p.permission_identifier === permission)) ||
          userResponse.object_permissions?.some((p) => p.permission_identifier === permission)

        acc[permission] = hasPermission ?? undefined
        return acc
      },
      { ...defaultUserPermissions }
    )

    return permissions
  })

  watchEffect(() => {
    console.log(userPermissions.value)
  })

  return {
    userPermissions,
  }
}

// Creates a ref that protects a boolean value based on project permissions.
// This composable ensures that the value can only be set to true if the user has the specified permission.
// It's useful for conditionally enabling/disabling features based on project-specific permissions.
export function useProjectPermissionProtectedRef({
  value,
  projectId,
  permission,
  // if true value is protected from being set to false
  reversed = false,
}: {
  value: boolean
  projectId: string
  permission: TPermissionOption
  reversed?: boolean
}) {
  const { userPermissions } = useUserProjectPermissions(projectId)
  let protectedValue = value
  let unsetValue = value

  const protectedRef = customRef<boolean>((track, trigger) => ({
    get() {
      track()
      return protectedValue
    },
    set(v: boolean) {
      const hasPermission = userPermissions.value?.[permission]

      if (!hasPermission && (reversed ? v === false : v === true)) {
        unsetValue = v
        return
      }

      protectedValue = v
      trigger()
    },
  }))

  watch(
    () => userPermissions.value,
    (newConfig, oldConfig) => {
      if (!newConfig) return

      const hasPermission = newConfig[permission] ?? false

      // If the permission list config is updated and the unset value matches the desired state
      if (!oldConfig && ((reversed && unsetValue === false) || (!reversed && unsetValue === true))) {
        protectedRef.value = unsetValue
      }
      // Set the protected ref based on the permission and reversed mode
      if (!hasPermission) {
        if (reversed) {
          protectedRef.value = true
        } else {
          protectedRef.value = false
        }
      }
    },
    { immediate: true }
  )

  return protectedRef
}
